import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { graphql } from "gatsby";
import { compact, get, last } from "lodash";
import PropTypes from "prop-types";
import { isMobile } from "react-device-detect";

import Banner from "../../components/Banner";
import { TextBlock, ContentBlock } from "../../components/Blocks";
import Contents from "../../components/Contents";
import Pages from "../../components/Pages";
import MapComponent from "../../components/Map";
import Collapse from "../../components/Collapse";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import { Share, Weather } from "../../widgets";
import Contacts from "../../components/Contacts";

import { makeUrl, makeNews, makeArticles, removePreloader, makeVirtualTourSlides } from "../../helpers";
import { PureCarousel } from "../../components/PureReactCarousel";
import { MetaImage, MetaPublicAccess, MetaName, MetaTelephone, MetaOpeningHours, MetaAddress, MetaGeo } from "../../components/Meta";

import { CHAPEL_MAX_UPDATE, CHAPEL } from "../../queries/queries.graphql";

export const query = graphql`
	query chapelsQuery($slug: String!) {
		hasura {
			...Chapel
		}
	}
`;

export default function Chapels({ data, pageContext, location }) {
	const path = last(compact(location.pathname.split("/")));

	const [chapels, setChapels] = useState(get(data, "hasura.chapels[0]", []));

	const maxUpdated = new Date(get(data, "hasura.chapels_aggregate.aggregate.max.updated_at", new Date()));

	const chapelOptions = {
		fetchPolicy: "no-cache",
		variables: {
			slug: path,
		},
	};

	const { loading: maxUpdateLoading, data: maxUpdateData, error: maxUpdateError } = useQuery(CHAPEL_MAX_UPDATE, chapelOptions);
	const [loadChapel, { loading: dataLoading, called, data: fetchData, error: dataError }] = useLazyQuery(CHAPEL, chapelOptions);

	useEffect(() => {
		const currentMaxUpdated = new Date(get(maxUpdateData, "chapels_aggregate.aggregate.max.updated_at", new Date()));

		if (maxUpdateData && !maxUpdateError) {
			if (+currentMaxUpdated !== +maxUpdated) {
				loadChapel();
			} else {
				removePreloader();
			}
		} else if (maxUpdateError) {
			console.error("Invalid load ChapelMaxUpdate", { maxUpdateError });
			removePreloader();
		}
	}, [maxUpdateLoading]);

	useEffect(() => {
		if (called && fetchData && !dataError) {
			setChapels(get(fetchData, "chapels[0]", {}));
		}

		removePreloader(!dataLoading || dataError);
	}, [dataLoading]);

	const bannerSlides = [
		{
			title: get(chapels, "page_title_full", ""),
			publicURL: get(chapels, "main_image.src"),
			mainImageMobile: get(chapels, "main_image_mobile.src", ""),
		},
	];

	const {
		main_image,
		title_full,
		page_title_full,
		page_title_short,
		telephone,
		openning_hours,
		main_image_preview,
		address,
		location_object,
	} = chapels;

	const url = makeUrl.chapel(chapels);
	const imageSrc = get(main_image_preview, "src", get(main_image, "src", ""));
	const content_blocks = get(chapels, "content_blocks", []);
	const news = makeNews(get(chapels, "chapels_news", []).map(item => item.news));
	const mediaArticles = makeArticles(get(chapels, "chapels_media_articles", []).map(item => item.media_article));
	const coordinates = get(
		get(chapels, "location_object.features", []).filter(
			feature => get(feature, "geometry.type", "") === "Point",
		),
		"[0].geometry.coordinates",
		[],
	);
	const weather = get(chapels, "city.weather", {});
	const virtualTour = makeVirtualTourSlides(get(chapels, "chapels_virtual_tours", []));

	return (
		<Pages entity={chapels} url={url}>
			<div itemScope itemType="https://schema.org/Place">
				<MetaPublicAccess content="true" />
				<MetaGeo content={coordinates} />
				<MetaImage content={imageSrc} />
				<MetaName content={title_full} />
				<MetaTelephone content={telephone} />
				<MetaOpeningHours content={openning_hours} />
				<MetaAddress content={address} />
				<ContentBlock key={"banner"}>
					<Banner slides={bannerSlides}>
						{isMobile && <Weather weather={weather} />}
					</Banner>
				</ContentBlock>
				<ContentBlock key={"breadcrumbs"}>
					<div className={"container d-flex justify-content-between"}>
						<Breadcrumbs currentLabel={title_full} pageContext={pageContext} />
						{!isMobile && <Weather weather={weather} />}
					</div>
				</ContentBlock>
				<ContentBlock key={"main-content"}>
					<div className={"container"}>
						<Collapse contentBlocks={content_blocks}>
							<Contents items={content_blocks} />
						</Collapse>
					</div>
				</ContentBlock>
				<ContentBlock key={"news"} mount={news && !!news.length}>
					<div className={"container"}>
						<TextBlock title={"Новости"} list={"Смотреть всё"} listLink={makeUrl.contentNews(pageContext)} allowBtn={news && news.length > 5}>
							<PureCarousel
								type={"SlideNews"}
								items={news}
								mediaSize={["100%", 400]}
								slideRatio={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 4,
								}}
								slideRatioMobile={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 4,
								}}
								params={{
									slidesPerView: 4,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											width: 768,
											slidesPerView: 2,
										},
										{
											width: 950,
											slidesPerView: 3,
										},
									],
								}}
							/>
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock key={"articles"} mount={mediaArticles && !!mediaArticles.length}>
					<div className={"container"}>
						<TextBlock title={"Статьи"}>
							<PureCarousel
								type={"SlideNews"}
								items={mediaArticles}
								mediaSize={["100%", 400]}
								slideRatio={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 5.5,
								}}
								slideRatioMobile={{
									naturalSlideWidth: 3,
									naturalSlideHeight: 4,
								}}
								params={{
									slidesPerView: 4,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											width: 576,
											slidesPerView: 2,
										},
										{
											width: 768,
											slidesPerView: 3,
										},
										{
											width: 1136,
											slidesPerView: 4,
										},
									],
								}}
							/>
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock key={"virtual_tours"} mount={virtualTour && !!virtualTour.length}>
					<div className={"container"} >
						<TextBlock title={"Виртуальные экскурсии"} id={"Virtual-Tours"}>
							<PureCarousel
								type={"SlideLinks"}
								items={virtualTour}
								slideRatio={{
									naturalSlideWidth: 2,
									naturalSlideHeight: 1,
								}}
								slideRatioMobile={{
									naturalSlideWidth: 1,
									naturalSlideHeight: 0.8,
								}}
								params={{
									slidesPerView: 2,
									breakpoints: [
										{
											width: 0,
											slidesPerView: 1,
										},
										{
											width: 576,
											slidesPerView: 2,
										},
										{
											width: 576,
											slidesPerView: 2,
										},
										{
											width: 1136,
											slidesPerView: 2,
										},
									],
								}}
							/>
						</TextBlock>
					</div>
				</ContentBlock>
				<ContentBlock mount={chapels && address || openning_hours || telephone} key={"contacts"}>
					<div className={"container"}>
						<div className={"row"}>
							<div className={"col-12"}>
								<TextBlock title={"Контакты"} className={"pb-2"}/>
							</div>
						</div>
						<Contacts address={address} hours={openning_hours} telephone={telephone}/>
					</div>
				</ContentBlock>
				<ContentBlock mount={chapels && location_object} key={"map"}>
					<div className={"container"}>
						<div className={"row"}>
							<div className={"col-12"}>
								<TextBlock title={"Расположение"} />
							</div>
						</div>
					</div>
					<MapComponent items={[chapels]} showPolygon />
				</ContentBlock>
				<div className={"container"}>
					<Share
						url={url}
						pageTitleShort={page_title_short}
						pageTitleFull={page_title_full}
						imageSrc={imageSrc}
					/>
				</div>
			</div>
		</Pages>
	);
}

Chapels.propTypes = {
	data: PropTypes.object,
	location: PropTypes.object,
	pageContext: PropTypes.object,
	pageData: PropTypes.object,
};

Chapels.defaultProps = {
	data: {},
	location: {
		pathname: "",
	},
	pageContext: {},
	pageData: {},
};
